.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

select {
  height: 40px;
}

@media screen and (max-width: 768px) {
  select {
    height: 20px;
  }
  
}

.grid line {
  stroke: lightgray;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

/* ==========================================
    FOR DEMO PURPOSE
  ========================================== */
  /* body {
    background: #2a2e32;
  } */
  
  .text-small {
    font-size: 0.9rem;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  a:hover, a:focus {
    text-decoration: none;
  }
  
  /* .form-control {
    background: #212529;
    border-color: #545454;
  }
   */
  /* .form-control:focus {
    background: #212529;
  } */
  
  footer {
    background: #212529;
  }
  
  
  /* ==========================================
      CUSTOM UTILS CLASSES
    ========================================== */
    body, html {
      height: 100%;
    }