body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#holder {
  overflow: hidden;
  position: relative;
}

.stepHolder {
  position: relative;
  width: var(--shift-distance);
  overflow: hidden;
  
}

.window {
  width: calc(var(--shift-distance) * 2);
  display:flex
}

@keyframes scaleDown {
  0% {
    transform: scale(1) ;
  }
  100% {
    transform: scale(0.8) ;
  }
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateX(calc(var(--shift-distance) * -1)) ;
  }
  100% {
    transform: scale(1) translateX(calc(var(--shift-distance) * -1)) ;
  }
}
@keyframes shiftRight {
  0% {
    transform: scale(0.8) translateX(0%) ;
  }
  100% {
    transform: scale(0.8) translateX(calc(var(--shift-distance) * -1)) ; /* Adjust this value for the right shift */
  }
}

@keyframes shiftLeft {
  0% {
    transform: scale(0.8) translateX(calc(var(--shift-distance) * -1)) ;
  }
  100% {
    transform: scale(0.8) translateX(0px) ; /* Adjust this value for the right shift */
  }
}

@keyframes scaleDownReverse {
  0% {
    transform: scale(1) translateX(calc(var(--shift-distance) * -1)) ;
  }
  100% {
    transform: scale(0.8) translateX(calc(var(--shift-distance) * -1)) ;
  }
}

@keyframes scaleUpReverse {
  0% {
    transform: scale(0.8) ;
  }
  100% {
    transform: scale(1) ;
  }
}


.scaleAndShiftRight {
  /* transform: translateZ(0);
  transform: translate3d(0, 0, 0); */
  will-change: transform;
  animation: scaleDown 0.3s ease forwards, 
             shiftRight 0.3s 0.3s ease forwards, 
             scaleUp 0.3s 0.6s ease forwards;
}

.scaleAndShiftLeft {
  /* transform: translateZ(0);
  transform: translate3d(0, 0, 0); */
  will-change: transform;
  animation: scaleDownReverse 0.3s ease forwards,
             shiftLeft 0.3s 0.3s ease forwards, 
             scaleUpReverse 0.3s 0.6s ease forwards; 
}

.form-select {
  height: 50px;
}

.income .form-select {
  padding-left: 3px;
  padding-right: 1px;
  height: 40px;
}